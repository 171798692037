import { render, staticRenderFns } from "./objectRecovery.vue?vue&type=template&id=44775c11&scoped=true&lang=pug"
import script from "./objectRecovery.vue?vue&type=script&lang=js"
export * from "./objectRecovery.vue?vue&type=script&lang=js"
import style1 from "./objectRecovery.vue?vue&type=style&index=1&id=44775c11&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44775c11",
  null
  
)

export default component.exports