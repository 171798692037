<template lang="pug">
el-dialog(
  custom-class='companiesList',
  :title='$t("objecrRecoveryTranslater.copyObject")',
  :visible.sync='dialogVisible',
  :modalAppendToBody='false',
  @close='handleClose',
  top='30vh',
  width='40%'
)
  .p.p4.mr-4(style='color: #5477a9', slot='title') {{ $t('objecrRecoveryTranslater.restore') }} <b class='boldText'> {{ current.name }} </b> {{ $t('objecrRecoveryTranslater.objectCompany') }} <b class='boldText'> {{ current.company_name || "" }} </b> ?
  .flex.w-full.justify-center.p-4
    skif-button.btn.cursor-pointer(@click='save') {{ $t('objecrRecoveryTranslater.restoring') }}
</template>

<script>
import { mapState } from 'vuex'
// import { objectsApi } from '@/api'
import { adminPanel } from '@/api'

export default {
  props: ['current'],
  data() {
    return {
      dialogVisible: true,
      hideDialog: false,
      filterString: '',
      myCompanies: [],
      isRowClicked: '',
      innerVisible: false,
      currentCompany: '',
      formData: {
        type: 'units',
        ids: '',
        to_company_id: ''
      }
    }
  },
  computed: {
    ...mapState('login', {
      companies: (state) => state.me.companies
    }),
    filterData() {
      return this.myCompanies.filter((company) =>
        JSON.stringify(company)
          .toLowerCase()
          .includes(this.filterString.toLowerCase())
      )
    }
  },
  methods: {
    async save() {
      this.formData.to_company_id = this.currentCompany.id
      this.formData.ids = [this.current.id]
      await adminPanel.unitRecovery(
        this.current.id,
        (response) => {
          this.hideDialog = true
          this.dialogVisible = false
          this.$showSuccess(this.$t('objecrRecoveryTranslater.success'))
          this.$emit('sucess', 'sucess')
        },
        (error) => {
          console.log('error', error)
          this.$showError(error.response.data.message)
        }
      )
    },

    handleClose() {
      this.$emit('close-dialog', 'close')
    }
  },
  mounted() {
    this.myCompanies = this.companies
  }
}
</script>

<style lang="scss" scoped></style>
<style>
.btn {
  width: 30%;
}
.boldText {
  font-size: 16px;
  font-weight: bolder;
}
.innerDialog .el-dialog__header {
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.innerDialog .el-dialog__header {
  color: #5477a9;
}
.innerDialog .el-dialog__body {
  padding-bottom: 20px;
  padding-top: 10px;
}
.company-active {
  background-color: #eef5ff;
}
.copanies-container {
  height: 200px;
}
.companiesList {
  width: 335px;
}
.companiesList .el-dialog__title {
  color: #3c5e8f;
  font-weight: bold;
}
.companiesList .el-dialog__body {
  padding-top: 3px;
  padding-bottom: 3px;
  padding: 0;
}
.saveBtn {
  color: #5477a9;
  font-weight: 600;
}

.cancelBtn {
  color: #cfdbeb;
  font-weight: 600;
}
.text {
  color: #a0a0a0;
  font-weight: 600;
}
ul li {
  color: #3c5e8f;
  font-size: 14px;
  font-weight: 600;
}
</style>
